body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden !important;
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: white;
}

.inner-pad {
  padding: 80px 0px;
}

/* navbar */
.navbar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
  display: block;
  background-color: #d9dfe6;
}

.navbar-light .navbar-nav .nav-link {
  font-size: 18px;
  color: black;
  text-transform: uppercase;
  font-family: sans-serif;
  font-weight: 500;
}


.company-logo {
  height: 9vh;
}

.nav-link {
  position: relative;
  text-decoration: none;
}

.nav-link::before {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  background-color: #8c2828;
  transition: width 0.3s;
}

.nav-link:hover::before {
  width: 100%;

}

.nav-item {
  padding: 0px 8px;
}

/* -------- */

/* home-page */

#join_now {
  font-size: 18px;
  font-weight: 600;
}

.some-info-career {
  font-size: 18px;
  color: white;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.bg-theme_color {
  background-color: #8c2828;
  padding: 50px 45px;
  border-radius: 15px;
  border: 2px solid white;
}

@keyframes zoomIn {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.2);
  }
}

.zoom-in-image {
  animation: zoomIn 5s;
  transform-origin: center center;
}

.carousel-item {
  overflow: hidden;
}

#background-image_textright {
  background-image: url('assets/images/v2.png');
  background-size: 100% 100%;
  background-attachment: fixed;
}

#background-image_textleft {
  background-image: url('assets/images/v3.png');
  background-size: 100% 100%;
  background-attachment: fixed;
}

.carousel-indicators {
  display: none;
}

.carousel-control-prev-icon {
  position: absolute;
  top: 45%;
}

.carousel-control-next-icon {
  position: absolute;
  top: 45%;
}

.carousel-item img {
  height: 90vh;
}

.carousel-caption {
  position: absolute;
  top: 30%;
}

.carousel-caption h1 {
  font-size: 45px;
  text-transform: uppercase;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  color: white;
  font-weight: 600;
}

.carousel-caption p {
  font-size: 19px;
  font-family: 'Times New Roman', Times, serif;
  margin-top: 20px;
  margin-bottom: 36px;
}

.Rd-mr-button {
  text-decoration: none;
  background-color: transparent;
  border-color: white !important;
  color: white;
  padding: 12px 22px;
  font-size: 19px;
  letter-spacing: 1px;
  border: 2px solid white;
  border-radius: 10px;
}

.Rd-mr-button:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  color: white;
  border-bottom: 4px solid white;
}

section {
  padding: 60px 0;
}

.sec-title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

.sec-title .title {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 24px;
  color: #8c2828;
  font-weight: 500;
  margin-bottom: 15px;
}

.sec-title h2 {
  position: relative;
  display: block;
  font-size: 33px;
  line-height: 1.28em;
  color: #222222;
  font-weight: 600;
  padding-bottom: 18px;
}

.sec-title h2:before {
  position: absolute;
  content: '';
  left: 0px;
  bottom: 0px;
  width: 50px;
  height: 3px;
  background-color: #8c2828;
}

.sec-title .text {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #848484;
  font-weight: 400;
  margin-top: 35px;
}

.sec-title.light h2 {
  color: #ffffff;
}

.sec-title.text-center h2:before {
  left: 50%;
  margin-left: -25px;
}

.list-style-one {
  position: relative;
}

.list-style-one li {
  position: relative;
  font-size: 16px;
  line-height: 26px;
  color: #222222;
  font-weight: 400;
  padding-left: 35px;
  margin-bottom: 12px;
}

.list-style-one li:before {
  content: "\f058";
  position: absolute;
  left: 0;
  top: 0px;
  display: block;
  font-size: 18px;
  padding: 0px;
  color: #ff2222;
  font-weight: 600;
  -moz-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1.6;
  font-family: "Font Awesome 5 Free";
}

.list-style-one li a:hover {
  color: #8c2828;
}

.btn-style-one {
  position: relative;
  display: inline-block;
  font-size: 17px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 30px;
  font-weight: 600;
  overflow: hidden;
  letter-spacing: 0.02em;
  background-color: #8c2828;
}

.btn-style-one:hover {
  background-color: #8c2828;
  color: #ffffff;
}

/* Creative Agency About Styles */

.creative-agency-about-area {
  padding: 120px 0;
}

.architecture-about-area {
  background: #0c0c0c;
}

.ca-about-left p {
  color: #5e5d5d;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 15px;
}

.wlc {
  font-size: 27px;
  font-family: cursive;
}

.ebar-heading {
  color: #8c2828 !important;
  margin: 20px 0px;
  font-size: 45px;
  text-transform: uppercase;
}

.clinic-about-area .clinic-about-left p,
.architecture-about-area .architecture-about-left p {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  color: black;
}

.ca-about-left .ca-about-btn-wrapper {
  margin-top: 30px;
}

.ca-about-left .ca-about-btn {
  display: inline-block;
  padding: 15px 30px;
  background: #8c2828;
  color: #fff;
  border: 2px solid transparent;
  font-family: "Josefin Sans", sans-serif;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
}

.architecture-about-left .architecture-about-btn {
  background: #f94747;
}

.clinic-about-left .clinic-about-btn {
  background: #8c2828;
  text-decoration: none;
}

.ca-about-left .ca-about-btn:hover {
  background: transparent;
  border: 3px solid #8c2828;
  color: black;
}

.clinic-about-left .clinic-about-btn:hover {
  background: transparent;
  border-color: #8c2828;
}

.architecture-about-left .architecture-about-btn:hover {
  background: transparent;
  border-color: #f94747;
}

.ca-about-right .ca-about-img {
  overflow: hidden;
}

.architecture-about-right .architecture-about-img img {
  border-color: #f94747;
}

.clinic-about-right .clinic-about-img img {
  border-color: #8c2828;
}

.ca-about-right .ca-about-img img:hover {
  transform: scale(1);
}

.backgrounds-set {
  background-attachment: fixed;
  background-size: 100% 100%;
  background-attachment: fixed;
  background-color: #e8eaed;
  padding: 90px 0px;
}

.prd-text {
  text-align: center;
  color: #8c2828;
  font-size: 45px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-transform: uppercase;

  font-weight: 500;
}

.margin-inner {
  margin-top: 60px;
}

.card-title {
  text-align: center;
  font-size: 25px;
}

.card-text {
  text-align: center;
}

.pr-btn {
  display: block;
  width: 100%;
  margin: auto;
  color: #8c2828;
  border: none;
  border-bottom: 2px solid #8c2828;
  border-radius: 10px;
  background-color: transparent !important;
  font-size: 20px;
}

.pr-btn:hover {
  display: block;
  width: 100%;
  margin: auto;
  color: #8c2828;
  border: none;
  border-bottom: 4px solid #8c2828;
  border-radius: 10px;
  background-color: transparent !important;
}

.mr-btn {
  color: #8c2828;
  border-radius: 10px;
  width: auto;
  display: block;
  margin: auto;
  font-size: 22px;
  text-decoration: none;
  border-bottom: 3px solid #8c2828;
  padding: 0px 50px;
  font-weight: 600;
}

.mr-btn:hover {
  border-bottom: 5px solid #8c2828;
  color: #8c2828;
}

/* --------  */
/* footer */
.faicons{
  display: flex;
  flex-wrap: wrap;
}
.all-icons{
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: black;
  border-radius: 50%;
  color: white;
  font-size: 15px;
  z-index: 1;
}
.footer.footer-2 .main-footer {
  background: 0 0
}

.footer.footer-2 .main-footer .info .part-txt p {
  color: black !important
}

.footer.footer-2 .main-footer .links ul li a {
  color: black !important
}

.footer.footer-2 .main-footer .links ul li a:hover {
  color: black !important
}

.footer.footer-2 .copyright {
  background: #211664
}

.footer .main-footer {
  padding: 80px 0;
  background-color: #d9dfe6;
}

.footer .main-footer .info .logo {
  /*    width:165px;*/
  margin-bottom: 31px
}

.footer .main-footer .info .part-txt p {
  font-size: 17px;
  line-height: 30px;
  color: black;
  margin-bottom: 12px;
  font-weight: 600;
}

.footer .main-footer .info .part-txt p:last-child {
  margin-bottom: -9px
}

.footer .main-footer .links {
  margin-bottom: -11px
}
.basic-info p{
  font-size: 17px;
  color: black !important;
  font-weight: 600;
}
.footer .main-footer .links h3 {
  font-size: 33px;
  line-height: 100%;
  font-weight: 700;
  color: Black;
  margin-top: -4px;
  margin-bottom: 36px
}

.footer .main-footer .links ul {
  padding: 0;
  margin: 0
}

.footer .main-footer .links ul li {
  list-style: none
}

.footer .main-footer .links ul li a {
  font-size: 16px;
  line-height: 34px;
  color: black;
  font-weight: 600;
}

.footer .main-footer .links ul li a:hover {
  color: #8c2828;
  font-weight: 600;
}

.footer .copyright {
  background: #222;
  text-align: center
}

.footer .copyright p {
  font-size: 16px;
  line-height: 100%;
  color: #fff;
  margin-top: 0;
  padding-top: 26px;
  margin-bottom: 0;
  padding-bottom: 28px
}

/* -->-->-- */
/* breadcrumb */
.breadcrumbs h2 {
  padding: 200px 0px;
  color: white;
  font-size: 45px;
}
.breadcrumbs{
  background: no-repeat;
  background-size: cover;
}

/* ----- */
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
  line-height: 1.2;
}

/* about page  */
.box-shadow {
  display: flex;
  justify-content: space-around;
  background: #d9dfe6;
  padding: 27px;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.2) 17px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}

#back-abt {
  background-image: url('assets/images/v7.jpg');
  background-size: 100% 100%;
  background-attachment: fixed;
}

.aboutus-v h4 {
  color: #f47a25 !important;
  margin: 20px 0px;
  font-size: 45px;
  text-transform: uppercase
}

.aboutus-v p {
  font-size: 19px;
  color: black;
}

.aboutus-m h4 {
  color: #033148 !important;
  margin: 20px 0px;
  font-size: 45px;
  text-transform: uppercase
}

.aboutus-m p {
  font-size: 19px;
  color: black;
}

.aboutus-val h4 {
  color: #9bc847 !important;
  margin: 20px 0px;
  font-size: 45px;
  text-transform: uppercase
}

.aboutus-val p {
  font-size: 19px;
  color: black;
}

#abt-background {
  background-image: url("assets/images/v7.jpg");
  background-size: 100% 100%;
  background-attachment: fixed;
}
.main-about{
  overflow-x: hidden !important;
}
/* ---->/... */
/* contact form */

.main-contact{
  overflow-x: hidden !important;
}
.background-color_media {
  background-color: #8c2829;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 55px;
  width: 55px;
  color: white;
  border-radius: 50%;
  margin-left: 15px;
  font-size: 22px;
}

.main-ctc-head {
  color: #8c2828 !important;
}

.ctc-bg-img {
  background-image: url(assets/images/contact.jpg);
  background-size: 100% 100%;
  background-color: #242424;
  background-blend-mode: multiply;
  background-attachment: fixed;

}

.section-header {
  margin-bottom: 50px;
  text-align: center;
}

.section-header h2 {
  color: #FFF;
  font-weight: bold;
  font-size: 3em;
  margin-bottom: 20px;
}

.section-header p {
  color: #FFF;
}

.contact-info {
  padding: 0px 30px;
  border-radius: 12px;
}

.contact-info-item {
  display: flex;
  margin-bottom: 30px;
  width: max-content;
}

.contact-info-icon {
  height: 70px;
  width: 70px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
}

.contact-info-icon i {
  font-size: 30px;
  line-height: 70px;
}

.contact-info-content {
  margin-left: 20px;
}

.contact-info-content h4 {
  color: #1da9c0;
  font-size: 1.4em;
  margin-bottom: 5px;
}

.contact-info-content p {
  color: #FFF;
  font-size: 1em;
}


.ctc-touch {
  color: white;
  margin-bottom: 49px;
  text-align: center;
}


.contact-form {
  padding: 30px 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
}


.contact-form textarea {
  resize: none;
}

.contact-form .form-input,
.form-text-area {
  background-color: #f0f4f5;
  height: 50px;
  padding-left: 16px;
}

.contact-form .form-text-area {
  background-color: #f0f4f5;
  height: auto;
  padding-left: 16px;
}

.contact-form .form-control::placeholder {
  color: #aeb4b9;
  font-weight: 500;
  opacity: 1;
}

.contact-form .form-control:-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control::-ms-input-placeholder {
  color: #aeb4b9;
  font-weight: 500;
}

.contact-form .form-control:focus {
  border-color: #8c2829;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.07), 0 0 8px #8c2829;
}

.contact-form .title {
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.contact-form .description {
  color: black;
  font-size: 14px;
  text-align: center;
}

.contact-form .submit-button-wrapper {
  text-align: center;
}

.contact-form .submit-button-wrapper input {
  border: none;
  border-radius: 4px;
  background-color: #8c2829;
  color: white;
  text-transform: uppercase;
  padding: 10px 60px;
  font-weight: 500;
  width: 100%;
  letter-spacing: 2px;
}

.contact-form .submit-button-wrapper input:hover {
  background-color: #8c2829;
}

.map {
  width: 100%;
  height: 450px;
}

/* end  */
/* manufacturing */
.manufacturing-p {
  font-size: 20px;
}

.card-manufacturing {
  width: 100%;
  height: auto;
  border-radius: 6px;
  padding: 4rem;
  place-self: center;
  background-color: white;
  font-family: sans-serif;
  box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3);
}

.title {
  padding-bottom: 0.5rem;
  font-size: 1.8rem;
}

.line {
  width: 20%;
  height: 5px;
  background-color: #E48E5E;
  border: transparent;
  margin-bottom: 4rem;
}

.steps {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.step {
  width: 100%;
}

.step__title {
  position: relative;
  background-color: #8e2729;
  height: 40px;
  width: 90%;
  display: grid;
  place-items: center;
  color: #fff;
  font-family: sans-serif;
}

.step__title:before,
.step__title:after {
  content: "";
  position: absolute;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.step__title:before {
  border-left: 20px solid white;
  left: 0;
}

.step__title:after {
  border-left: 20px solid #8e2729;
  right: -20px;
}

.step__info {
  padding: 1rem 0.5rem;
  font-size: 21px;
}

.title-box-manu {
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-top: 7px solid #8c2828;
  padding: 30px 55px;
}

.title-box_title {
  color: #8c2828;
  display: block;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: 600;
}

.sec-title-manu {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.fa-chevron-right {
  color: #8c2828;
  font-size: 19px;
}

.title-para_manu p {
  font-size: 20px;
}

/* end */

/* main for products hieght  */
.custom-image-height {
  height: 45vh;
  width: 100%;
}

.card-products {
  width: 100%;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.85);
  position: relative;
  display: inline-block;
}

.card-products:hover {
  box-shadow: 0 16px 36px 0 rgba(0, 0, 0, 0.7);
  transform: translate(0, -5px);
}

.author-products {
  text-decoration: none;
  color: rgb(255, 255, 255);
  font-size: 17px;
}

.author-products:hover {
  text-decoration: underline;
}

.card-body-products {
  padding: 40px;
  height: auto;
}

.card-products-title {
  color: #8c2828;
  margin-bottom: 18px;
}

/*  */
/* home  - products */
.card-home-products {
  height: 25rem;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  color: #f0f0f0;
  box-shadow: 0 10px 10px 3px rgba(0, 0, 0, 0.4);
}

.card-home-products img {
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity 0.2s ease-out;
}

.card-home-products h2 {
  position: absolute;
  inset: auto auto 30px 30px;
  margin: 0;
  transition: inset 0.3s 0.3s ease-out;
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
}

.card-home-products p,
.card-home-products a {
  position: absolute;
  opacity: 0;
  max-width: 80%;
  transition: opacity 0.3s ease-out;
}

.card-home-products p {
  inset: auto auto 80px 30px;
}

.card-home-products a {
  inset: auto auto 40px 30px;
  color: inherit;
  text-decoration: none;
}

.card-home-products:hover h2 {
  inset: auto auto 220px 30px;
  transition: inset 0.3s ease-out;
}

.card-home-products:hover p,
.card-home-products:hover a {
  opacity: 1;
  transition: opacity 0.5s 0.1s ease-in;
}

.card-home-products:hover img {
  transition: opacity 0.3s ease-in;
  opacity: 1;
}

.material-symbols-outlined {
  vertical-align: middle;
}

/* end ----- */
/* products - page */

.card-hover {
  height: 500px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 32px -10px rgba(0, 0, 0, 0.08);
  border: 2px solid black;
  border-radius: 15px;
}

.card-hover:has(.card-hover__link:hover) .card-hover__extra {
  transform: translateY(0);
  transition: transform 0.35s;
}

.card-hover:hover .card-hover__content {
  background-color: #DEE8C2;
  bottom: 100%;
  transform: translateY(100%);
  padding: 50px 60px;
  transition: all 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover:hover .card-hover__link {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.3s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover:hover img {
  transform: scale(1);
  transition: 0.35s 0.1s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

.card-hover__content {
  width: 100%;
  text-align: center;
  background-color: #86B971;
  padding: 0 60px 50px;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(0);
  transition: all 0.35s 0.35s cubic-bezier(0.1, 0.72, 0.4, 0.97);
  will-change: bottom, background-color, transform, padding;
  z-index: 1;
}

.card-hover__content::before,
.card-hover__content::after {
  content: '';
  width: 100%;
  height: 120px;
  background-color: inherit;
  position: absolute;
  left: 0;
  z-index: -1;
}

.card-hover__content::before {
  top: -80px;
  clip-path: ellipse(60% 80px at bottom center);
}

.card-hover__content::after {
  bottom: -80px;
  clip-path: ellipse(60% 80px at top center);
}

.card-hover__title {
  font-size: 1.5rem;
  margin-bottom: 1em;
  text-transform: uppercase;
}

.card-hover__title span {
  color: #2d7f0b;
}

.card-hover__text {
  font-size: 17px;
}

.card-hover__link {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%, 10%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-decoration: none;
  color: #2d7f0b;
  opacity: 0;
  padding: 10px;
  transition: all 0.35s;
}

.card-hover__link:hover svg {
  transform: translateX(4px);
}

.card-hover__link svg {
  width: 18px;
  margin-left: 4px;
  transition: transform 0.3s;
}

.card-hover__extra {
  height: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-size: 1.5rem;
  text-align: center;
  background-color: #86b971;
  padding: 80px;
  bottom: 0;
  z-index: 0;
  color: #dee8c2;
  transform: translateY(100%);
  will-change: transform;
  transition: transform 0.35s;
}

.card-hover__extra span {
  color: #2d7f0b;
}

.card-hover img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  transform: scale(1.2);
  transition: 0.35s 0.35s transform cubic-bezier(0.1, 0.72, 0.4, 0.97);
}

/* end -------  */
/* careers  */
.tab-button-style {
  width: 100%;
}

.custom-btn-tab {
  padding: 8px 0px;
  font-size: 18px;
  border-radius: 10px;
  background-color: #d9dfe6;
  border: 2px solid #d9dfe6;
}

.active-tab {
  background-color: #8c2828;
  border-color: #8c2828;
  border-radius: 10px;
  color: white;
}

/* end  */
/* careers  */
.item-careers {
  margin: 20px 0px;
  padding: 18px 25px;
  border: 2px solid #8c2828;
  background-color: white;
  border-radius: 8px;
}

.title-type-careers {
  font-size: 22px;
  font-weight: 500;
  color: #0a2134;
}

.light-blue_bg-careers {
  background: #edf2f7;
  border-radius: 4px;
  text-transform: capitalize;
  text-align: center;
  padding: 8px 0px;
  font-weight: 400;
  letter-spacing: 1px;
  font-size: 13px;
}

.apply-careers {
  display: inline-block;
  padding: 9px 30px;
  background: #8c2828;
  color: #fff;
  border: 2px solid transparent;
  font-family: "Josefin Sans", sans-serif;
  transition: all 0.4s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 15px;
}

.apply-careers:hover {
  background: white;
  color: #8c2828;
  border: 2px solid #8c2828;

}
/* end  */

/* =================================================================================== */

/* Responsive css */

@media only screen and (max-width: 1024px) {
  .manufacturing-p {
    font-size: 17px;
}
.step__info {
  padding: 1rem 0.5rem;
  font-size: 17px;
}
.card-manufacturing {
  width: 100%;
  height:auto;
}
}
@media only screen and (max-width: 992px){
  .manufacturing-p {
    font-size: 15px;
}
.step__info {
  padding: 1rem 0.5rem;
  font-size: 15px;
}
.card-manufacturing {
  width: 100%;
  height: auto;
}
}
@media only screen and (max-width: 768px){
  .card-manufacturing {
    width: 100%;
    height: auto;
    border-radius: 6px;
    padding: 4rem;
    place-self: center;
    background-color: white;
    font-family: sans-serif;
    box-shadow: 6px 6px 20px rgba(0, 0, 0, 0.3);
  }
  .manufacturing-p {
    font-size: 14px;
}
  .footer .main-footer .links h3 {
    margin-top: 27px;
    margin-bottom: 36px;
}
  .aboutus-val p{
    font-size: 16px;
  }
  .aboutus-m p{
    font-size: 16px;
  }
  .aboutus-v p{
    font-size: 16px;
  }
  .clinic-about-area .clinic-about-left p, .architecture-about-area .architecture-about-left p {
    font-size: 14px;
}
}
@media only screen and (max-width: 480px) {
  .carousel-item img {
    height: 83vh;
  }
  .indian-map {
    height: 300px;
    display: block;
    margin: auto;
  }

  .contact-info-item {
    display: flex;
    margin-bottom: 30px;
    width: auto !important;
}

  .prd-text {
    text-align: center;
    font-size: 35px;
}
  .step__info {
    padding: 1rem 0.5rem;
    font-size: 17px;
}
  .card-manufacturing {
    width: 100%;
    height: auto;
  }
  .ca-about-left .ca-about-btn-wrapper {
    margin-top: 30px;
    display: block;
    margin: auto;
    width: max-content;

    margin-top: 40px;
}
  .manu-home-phone{
    height: 300px;
  }
  .aboutus-m h4 {
    text-align: center;
}
.aboutus-val h4 {
  text-align: center;
}
.aboutus-v h4 {
  text-align: center;
}
  .card-manufacturing {
    width: 100%;
    height: auto !important;
  }
  .phone-image{
    height: 270px;
    display: block;
    margin: auto;
  }

  .carousel-caption {
    position: absolute;
    top: 13%;
}
.carousel-caption h1 {
  margin-top: 30px;
  font-size: 35px;
}
.carousel-caption p {
  font-size: 16px;
  margin-top: 20px;
  margin-bottom: 36px;
}
.ebar-heading {
  font-size: 29px;
  text-align: center;
}
.clinic-about-area .clinic-about-left p, .architecture-about-area .architecture-about-left p {
  font-size: 15px;
  text-align: center;
}
.footer .main-footer .links h3 {
  font-size: 23px;
  line-height: 100%;
  font-weight: 700;
  color: Black;
  margin-top: 41px;
  margin-bottom: 29px;
}
}
.download_card{
  background-color: #8c2828;
  color: white;
  padding: 8px 14px;
  border-color: #8c2828;
  font-size: 20px;
}
.download_card:hover{
  color: white !important;

}
/* end  */


/* Vrajsheth@11 */